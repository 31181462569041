import { SemanticICONS } from "semantic-ui-react";
import { AbsoluteTimeRange } from "./components/Screens/Dashboards/Datetime/TimeRange";
import { DurationInputArg2 } from "moment";

declare global {
  interface Window {
    toastr: any;
    _archbee: any;
  }
}

export type Permission = {
  devices: { [key: string]: "all" | string[] };
  tables: { [key: string]: "all" | string[] };
  homepage: string;

  viewMetadata: "all" | string[];
  editMetadata: "all" | string[];

  dashboardPermittedShareRoles: "all" | number[];

  createDashboards: boolean;

  allowedActions: "all" | string[];

  viewFiles: boolean;
  editFiles: boolean;

  viewFirmwares: boolean;
  editFirmwares: boolean;

  viewDeviceConfigs: boolean;
  editDeviceConfigs: boolean;

  viewStreams: boolean;
  editStreams: boolean;

  viewUsers: boolean;
  editUsers: boolean;

  viewRoles: boolean;
  editRoles: boolean;

  viewActionTypes: boolean;
  editActionTypes: boolean;

  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;

  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
  editTenantSettings: boolean;

  allowCreatingDevices: boolean;
  allowMarkActionAsCompleted: boolean;
};

export type AtLeastOnePropRequired<T, K extends keyof T = keyof T> = {
  [Key in K]: Required<Pick<T, Key>> & Partial<Omit<T, Key>>;
}[K];

export type Role = {
  id: number;
  name: string;
  permissions: Permission;
};

export type ApiKey = {
  key?: any;
  role: string;
  name: string;
  created_by: string;
};

export type SimpleConditionOperator =
  | ">"
  | "<"
  | "="
  | "!="
  | ">="
  | "<="
  | "in";
export type CompositeConditionOperator = "and" | "or";
export type NoDateConditionOperator = "no_data";

export type SimpleCondition = {
  field: string;
  operator: SimpleConditionOperator;
  value: any;
};

export type CompositeCondition = {
  operator: CompositeConditionOperator;
  conditions: Array<Condition>;
};

export type NoDataCondition = {
  operator: NoDateConditionOperator;
  threshold_seconds?: number;
};

export type Condition = SimpleCondition | CompositeCondition | NoDataCondition;

export type SessionType = {
  name: string;
  stream: string;
  condition: Condition;
  status?: string;
  last_processed_timestamps?: { [key: number]: string };
};

export type SlackNotificationParams = {
  slack_channel: string;
  bot_token: string;
};

export type WebhookNotificationParams = {
  url: string;
  headers: { [key: string]: string };
};

export type EmailNotificationParams = {
  emails: string[];
};

export type SmsNotificationParams = {
  phone_numbers: string[];
};

export type NotificationChannelParameters =
  | SlackNotificationParams
  | WebhookNotificationParams
  | EmailNotificationParams
  | SmsNotificationParams;

export type AlertNotificationRule = {
  id?: string;
  alert_rule_id: string;
  channel_type: NotificationChannelType;
  channel_parameters: NotificationChannelParameters;
  interval_seconds: number;
  last_notified_at?: number;
  notification_template?: string;
};

export type AlertRuleCriticality = "critical" | "warning" | "info";

export interface AlertRule {
  id: string;
  name: string;
  criticality: AlertRuleCriticality;
  metadata_filters: { [key: string]: string[] };
  stream: string;
  condition: Condition;
  activation_threshold_seconds: number;
  deactivation_threshold_seconds: number;
  tenant_id?: string;

  status?: string;
  last_processed_timestamps?: Record<number, number | string>;
  notification_rules?: AlertNotificationRule[];
}

export type NotificationChannelType = "slack" | "webhook" | "email" | "sms";

export interface DBCAdditionalSettings {
  add_suffix_canid?: boolean;
  dont_decode_choices?: boolean;
  lower_threshold?: number | null;
  upper_threshold?: number | null;
  dbc_ver?: number | null;
}

export interface DBCData {
  name?: string;
  version?: string;
  input_table?: string;
  output_table?: string;
  period?: number;
  additional_settings?: DBCAdditionalSettings;
}

interface DBCSignals {
  byte_order: string;
  choices: any; // TODO dont know as all responses are null right now. will update
  is_signed: boolean;
  length: number;
  name: string;
  offset: number;
  scale: number;
  start: number;
}

export type EditDBCParserInput = {
  name: string;
  period: number | string;
  version: string;
  additional_settings: {
    dbc_ver: number | null;
    lower_threshold: number | null;
    upper_threshold: number | null;
  };
};

export interface DBCMessageResponse {
  can_id: number;
  name: string;
  signals: DBCSignals[];
}

interface DBCJSONResponse {
  messages: DBCMessageResponse[];
}

export interface ParsedDBCResponse {
  results: { json: DBCJSONResponse };
}

interface DBCFile {
  dbc: string;
}

export interface DBCFileResponse {
  results: DBCFile;
}

export interface AllDBCResponse {
  results: DBCResponse[];
}

export interface SingleDBCResponse {
  results: DBCResponse;
}

export interface DeleteDBCResponse {
  message: string;
}

interface DBCOffsetResponse {
  [key: string]: number;
}

export interface DBCResponse {
  id: string;
  name: string;
  tenant_id: string;
  version: string;
  input_table: string;
  output_table: string;
  status: string;
  period: number;
  offsets: DBCOffsetResponse;
  additional_settings: DBCAdditionalSettings | null;
}

export type LatencyHistogramStats = {
  range_start: number;
  range_end: number;
  count: number;
}[];

export type ProgressHistogramStats = {
  [key: number]: number[];
};

export interface IRoleResult {
  result: Array<Role>;
}

// Define the type for TabSettings
export type TabSettings = {
  show: boolean;
  [subTabName: string]: TabSettings | boolean;
};

export type ShowTabs = {
  [tabName: string]: TabSettings;
};

// Define the type for ShowTabsData
export type ShowTabsData = {
  device_management: { show: boolean };
  dashboards: { show: boolean };
  actions: {
    show: boolean;
  };
  alerts: {
    show: boolean;
  };
  dbc_parsers: {
    show: boolean;
  };
  device_components?: {
    show: boolean;
  };
};

export type CustomTimeRanges = {
  [x: string]:
    | AbsoluteTimeRange
    | {
        to: {
          date: string;
        };
        from: {
          date: string;
        };
      }
    | {
        to: {
          units: DurationInputArg2;
          duration: number;
        };
        from: {
          units: DurationInputArg2;
          duration: number;
        };
      };
};

export type TenantSettings = {
  common_settings: {
    pin_metadata: Array<string>;
  };
  "serial-key": string | null;
  show_tabs: ShowTabsData | null | undefined;
  dashboard_settings: {
    custom_time_ranges: {
      [x: string]:
        | AbsoluteTimeRange
        | {
            to: {
              date: string;
            };
            from: {
              date: string;
            };
          }
        | {
            to: {
              units: DurationInputArg2;
              duration: number;
            };
            from: {
              units: DurationInputArg2;
              duration: number;
            };
          };
    };
  };
  hardware_type: string;
  default_action_timeout?: number | Date;
  logo: {
    light: string;
    dark: string;
  };
  favicon: {
    light: string;
    dark: string;
  };
};

export type UserSettings = {
  theme: string;
  current_release_id: string;
  changelog_id: string;
  get_started: {
    deviceType: {
      [deviceType: string]: {
        [key: string]: boolean;
      };
    };
    selectedDevice: string | undefined;
    skip: boolean;
  };
};

export type User = {
  id: string;
  name: string;
  email: string;
  role: Role;
  tenants: Array<string>;
  tenant_id?: string;
  "tenant-settings"?: TenantSettings;
  settings?: UserSettings;
};

export interface IUser {
  name: string;
  email: string;
  roles: [number];
}

export interface IUserResult {
  result: { [key: string]: IUser };
}

export type Settings = {
  vin_number?: string;
  tenancy: "multi" | "single";
  default_tenant: string;
};

export type ActionsNavbarActiveItemType =
  | "Action Details"
  | "Inventory"
  | "New Action";

export type ActionType = {
  type: string;
  icon: SemanticICONS;
  payload_type: string;
  json_schema?: string | null;
  json_ui_schema?: string | null;
  timeout?: number | Date | null;
};

export interface Auth {
  url: string;
  type: string;
}

export function timeoutDelay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

// ====================== Archbee Pages Id ======================
export const urlToDocId = {
  devices: "device-management",
  firmwares: "creating-a-new-firmware-version",
  configurations: "creating-new-configuration",
  edit: "creating-new-configuration",
  "action-status": "actions",
  dashboards: "dashboards",
  general: "general-settings",
  roles: "roles",
  users: "users",
  streams: "streams-tables",
  metadata: "editing-device-metadata",
  action_types: "actions",
  api_keys: "api-keys",
  session_types: "sessions",
  alerts: "alert-system",
  "alert-system": "alert-system",
  "alert-notification": "notification-system",
  dbc: "dbc-parser-management",

  // ACTION_V2
  "firmware-files": "creating-a-new-firmware-version",
  "json-configurations": "creating-a-new-json-configuration-version",
  "geofence-configurations": "creating-a-new-geofence-configuration-version",
  inventory: "inventory-management",
  "action-summary": "actions",
  "action-overview": "actions",
  "device-overview": "actions",
  //Actions V3
  actions: "actionsv2",
  "live-actions": "actionsv2",
  "new-action": "trigger-new-action",
};
// ==================== Archbee Pages Id Ends ====================

export function dropDownOptionsFromArray(array: string[]) {
  let options = [
    {
      key: "",
      value: "",
      text: "No options available",
    },
  ];
  if (array !== undefined && array.length > 0) {
    options = array.map((s) => {
      return {
        key: s,
        value: s,
        text: s,
      };
    });
  }
  return options;
}

export const currentReleaseId = "r23-14-aug-2024"; // This should be updated with every release
export const currentReleaseVersion = "R23"; // This should be updated with every release
//This should be the last part of the URL of the changelog page in Archbee

// Email ID validation
export function validateEmail(email: string): boolean {
  // Validates that the email address starts with one or more characters that are not whitespace or '@',
  // followed by the '@' symbol, followed by one or more characters that are not whitespace or '@',
  // and ends with a dot ('.') followed by one or more characters that are not whitespace or '@'
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // [^\s@]+ Matches one or more characters that are not whitespace or '@'
  return emailRegex.test(email);
}

// Email ID validation, more restricted
export const validateRestrictedEmail = (emailString: string): boolean => {
  const restrictedEmailRegex: RegExp =
    /^(?!.*\.{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return restrictedEmailRegex.test(emailString);
};

// Whole Number Validation
export function validateWholeNumber(number: string): boolean {
  const wholeNumberRegex: RegExp = /^\d+$/;
  return wholeNumberRegex.test(number);
}

// Phone Number validation
export const validatePhoneNumber = (phoneNumber: string): boolean => {
  // This regex enforces the presence of a plus sign at the beginning (^\+), allows for 1 to 3 digits for the country code ([0-9]{1,3}), and then allows for optional characters like parentheses, hyphens, or spaces ([()\-\s]?). The rest of the digits are matched using [0-9]+. This pattern provides more flexibility for different formats of phone numbers while ensuring the plus sign for the country code.
  const phoneRegex: RegExp = /^\+[0-9]{1,3}[()\-\s]?[0-9]+$/;
  return phoneRegex.test(phoneNumber);
};

// Name validation
export function validateName(name: string): boolean {
  // [a-zA-ZÀ-ÖØ-öø-ÿ']+ Matches one or more alphabetic characters (uppercase or lowercase), accented characters, apostrophes, or hyphens.
  const nameRegex: RegExp = /^[a-zA-ZÀ-ÖØ-öø-ÿ']+(\s[a-zA-ZÀ-ÖØ-öø-ÿ']+)*$/;
  return nameRegex.test(name);
}

/**
 * Validates if a string contains only alphanumeric characters and underscores.
 *
 * @param {string} name - The string to be validated.
 * @returns {boolean} True if the string contains only alphanumeric characters and underscores, false otherwise.
 */
export function specialCharacterValidation(name: string): boolean {
  const specialCharacterRegex: RegExp = /^[a-zA-Z0-9_\s]*$/;
  return specialCharacterRegex.test(name);
}

/**
 * Validates if a string contains a valid URL.
 * This function uses the URL constructor to validate the URL, with additional checks for the hostname and domainPattern.
 * @param {string} url - The URL to be validated.
 * @returns {boolean} True if the URL is valid, false otherwise.
 */
export const isURLValid = (url: string): boolean => {
  try {
    const parsedURL = new URL(url);

    // Check for a valid protocol and hostname
    if (!parsedURL.protocol || !parsedURL.hostname) {
      return false;
    }

    // Check if the hostname is a valid domain
    // This checks for a domain with a top-level domain (TLD) of at least 2 characters
    // `^(?:[a-zA-Z0-9-]+\.)+` - This part of the pattern ensures that there is at least one subdomain. It matches one or more segments of the domain part separated by dots. Each segment can include alphanumeric characters and hyphens.
    // `[a-zA-Z]{2,}$` - This part matches the top-level domain (TLD), which must be at least two characters long.
    const domainPattern: RegExp = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (!domainPattern.test(parsedURL.hostname)) {
      return false;
    }

    return true;
  } catch (error) {
    console.log("URL is invalid, ", error);
    return false;
  }
};

// Hiding Get Started for enterprise users
export const showGetStartedForHost = [
  "cloud.bytebeam.io",
  "stage.bytebeam.io",
].includes(window.location.host);

// Hiding Get Started for enterprise users
export function isHostMicelio(): boolean {
  // stage,and localhost are added for testing purposes only
  return ["localhost", "stage", "micelio", "numeros"].some((host) =>
    window.location.host.includes(host)
  );
}

// Hiding tabs for micelio and numeros.
export function isHostMicelioHideTabs(): boolean {
  return ["micelio", "numeros"].some((host) =>
    window.location.host.includes(host)
  );
}

export function convertToURLParams(values: Object) {
  const e = Object.entries(values).map(([key, values]) => [
    key,
    Array.isArray(values) ? values.join(",") : values,
  ]);

  return new URLSearchParams(Object.fromEntries(e));
}
