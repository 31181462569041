import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Modal, Button } from "semantic-ui-react";
import { beamtoast } from "./CustomToast";
import { neverTimestamp } from "../Screens/Actions/NewAction/NewAction";
import { EditAnimatedMetaInput } from "../Screens/Dashboards/Panel/util";
import styled from "styled-components";
import { formatTimeoutDuration } from "../Screens/Settings/tenantsSettings/DefaultGlobalTimeout";

type StyledDropdownProps = {
  minWidth?: string;
  width?: string;
  maxWidth?: string;
};

const StyledDropdown = styled(Dropdown)<StyledDropdownProps>`
  min-width: ${(props) => props.minWidth || "192px"};
  width: ${(props) => props.width || "192px"};
  max-width: ${(props) => props.maxWidth || "192px"};
`;

type CustomTimeoutModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  customTimeoutUnit: string;
  setCustomTimeoutUnit: (unit: string) => void;
  handleCustomTimeout: (value: number, customTimeoutUnit: string) => void;
};

const timeoutUnitOptions = [
  { key: "mins", value: "mins", text: "mins" },
  { key: "hrs", value: "hrs", text: "hrs" },
  { key: "days", value: "days", text: "days" },
];

const oneMinInMs = 60 * 1000; // milliseconds in a minute
const oneHrInMs = 60 * oneMinInMs; // milliseconds in an hour
const oneDayInMs = 24 * oneHrInMs; // milliseconds in a day

const CustomTimeoutModal = ({
  isOpen,
  setIsOpen,
  customTimeoutUnit,
  setCustomTimeoutUnit,
  handleCustomTimeout,
}: CustomTimeoutModalProps) => {
  const [timeoutValue, setTimeoutValue] = useState<number>(2);

  const handleCustomTimeoutChange = (value: number) => {
    setTimeoutValue(parseInt(value as any));
  };

  const handleSubmit = () => {
    if (timeoutValue <= 0) {
      beamtoast.error(
        `Custom Timeout value should be greater than 0 ${customTimeoutUnit}`
      );
      return;
    }
    setIsOpen(false);
    handleCustomTimeout(timeoutValue, customTimeoutUnit);
  };

  return (
    <Modal
      closeIcon
      size="mini"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
    >
      <Modal.Header>Add Custom Timeout</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Please set a custom timeout for Action</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <EditAnimatedMetaInput
              min="0"
              elementid="custom-timeout"
              placeholder="Enter Custom Timeout"
              type="number"
              label={`Custom Timeout (in ${customTimeoutUnit})`}
              value={timeoutValue}
              onChangeEvent={(e) => handleCustomTimeoutChange(e.target.value)}
              style={{ width: "100%" }}
            />
            <Dropdown
              elementid="custom-timeout-unit"
              placeholder="Select Unit"
              selection
              options={timeoutUnitOptions}
              value={customTimeoutUnit}
              onChange={(e, { value }) => setCustomTimeoutUnit(value as string)}
              style={{
                minWidth: "95px",
                marginBottom: "10px",
                padding: "5px 10px",
              }}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button primary onClick={handleSubmit}>
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

type TimeoutDropdownProps = {
  saveLoading?: boolean;
  timeoutDuration: number | Date | null | undefined;
  setTimeoutDuration: (timeoutDuration: number | Date) => void;
  allowNever?: boolean;
  allowClearable?: boolean;
  minwidthdropdown?: string;
  maxwidthdropdown?: string;
  widthdropdown?: string;
};

/**
 * This component is used to render the Timeout Dropdown, which is used to set and display the timeout duration.
 * Timeout duration can be selected from the dropdown options or a custom timeout can be set using the custom timeout modal.
 * Sends the selected timeout duration to the parent component using the setTimeoutDuration function.
 * @param timeoutDuration - The current timeout duration
 * @param setTimeoutDuration - The function to set the timeout duration
 */
const TimeoutDropdown = ({
  saveLoading = false,
  timeoutDuration,
  setTimeoutDuration,
  allowNever = true,
  allowClearable = false,
  minwidthdropdown,
  maxwidthdropdown,
  widthdropdown,
}: TimeoutDropdownProps) => {
  const [isCustomTimeoutOpen, setIsCustomTimeoutOpen] =
    useState<boolean>(false);
  const [customTimeoutUnit, setCustomTimeoutUnit] = useState<string>("days");
  const [timeoutDisplay, setTimeoutDisplay] = useState<string>("Never");

  const timeOutAfterOptions = useMemo(
    () => [
      {
        key: "Select Custom Time",
        text: "Select Custom Time",
        icon: "add",
        onClick: () => setIsCustomTimeoutOpen(true),
      },
      ...(allowNever
        ? [{ key: "Never", text: "Never", value: neverTimestamp }]
        : []),
      { key: "2 mins", text: "2 mins", value: 2 * oneMinInMs },
      { key: "5 mins", text: "5 mins", value: 5 * oneMinInMs },
      { key: "10 mins", text: "10 mins", value: 10 * oneMinInMs },
      { key: "30 mins", text: "30 mins", value: 30 * oneMinInMs },
      { key: "1 hr", text: "1 hr", value: oneHrInMs },
      { key: "6 hrs", text: "6 hrs", value: 6 * oneHrInMs },
      { key: "12 hrs", text: "12 hrs", value: 12 * oneHrInMs },
      { key: "1 day", text: "1 day", value: oneDayInMs },
      { key: "3 days", text: "3 days", value: 3 * oneDayInMs },
      { key: "7 days", text: "7 days", value: 7 * oneDayInMs },
      { key: "15 days", text: "15 days", value: 15 * oneDayInMs },
      { key: "30 days", text: "30 days", value: 30 * oneDayInMs },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleTimeoutChange = (e, data) => {
    e.preventDefault();

    const value = data.value as Date | number;
    setTimeoutDuration(value);
    setTimeoutDisplay(
      data.options.find((option) => option.value === value)?.text
    );
  };

  const handleCustomTimeout = (value: number, customTimeoutUnit: string) => {
    let timeoutUnitMultiplier: number;

    switch (customTimeoutUnit) {
      case "mins":
        timeoutUnitMultiplier = oneMinInMs;
        break;
      case "hrs":
        timeoutUnitMultiplier = oneHrInMs;
        break;
      case "days":
        timeoutUnitMultiplier = oneDayInMs;
        break;
      default:
        timeoutUnitMultiplier = oneDayInMs;
    }

    const timeoutValue = timeoutUnitMultiplier * value;

    const timeoutDisplayText =
      value === 1 && customTimeoutUnit.endsWith("s")
        ? `${value} ${customTimeoutUnit.slice(0, -1)}`
        : `${value} ${customTimeoutUnit}`;

    setTimeoutDisplay(timeoutDisplayText);
    setTimeoutDuration(timeoutValue);
  };

  useEffect(() => {
    if (timeoutDuration === neverTimestamp) {
      setTimeoutDisplay("Never");
    } else if (typeof timeoutDuration === "number" && timeoutDuration > 0) {
      const selectedOption = timeOutAfterOptions.find(
        (option) => option.value === timeoutDuration
      );
      if (selectedOption) {
        setTimeoutDisplay(selectedOption.text);
      } else {
        setTimeoutDisplay(formatTimeoutDuration(timeoutDuration));
      }
    } else {
      setTimeoutDisplay("Select timeout");
    }
  }, [timeoutDuration, timeOutAfterOptions]);

  return (
    <>
      <CustomTimeoutModal
        isOpen={isCustomTimeoutOpen}
        setIsOpen={setIsCustomTimeoutOpen}
        customTimeoutUnit={customTimeoutUnit}
        setCustomTimeoutUnit={setCustomTimeoutUnit}
        handleCustomTimeout={handleCustomTimeout}
      />
      <StyledDropdown
        clearable={allowClearable}
        elementid="time-out-after"
        placeholder="Select timeout"
        selection
        disabled={saveLoading}
        options={timeOutAfterOptions}
        value={timeoutDuration as number}
        onChange={handleTimeoutChange}
        text={timeoutDisplay}
        minWidth={minwidthdropdown}
        width={widthdropdown}
        maxWidth={maxwidthdropdown}
      />
    </>
  );
};

export default TimeoutDropdown;
