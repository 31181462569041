import { Menu, Button, Pagination, Input } from "semantic-ui-react";
import styled from "styled-components";
import { SearchPageInput } from "../Screens/Actions/action-status";
import AceEditor from "react-ace";

export const StyledPagination = styled(Pagination)`
  && {
    box-shadow: ${({ theme }) => theme.colors["pagination-box-shadow"]};
    width: fit-content !important;
    .item {
      background-color: ${({ theme }) =>
        theme.colors["card_pagination-item-background-color"]};
    }
    &.ui.pagination.menu .item:hover {
      background-color: ${({ theme }) =>
        theme.colors["card_pagination-item-background-hover-color"]} !important;
      box-shadow: none;
    }
    &.ui.pagination.menu .active.item {
      color: #ffffff;
      background-color: ${({ theme }) =>
        theme.colors["card_pagination-active-item-background-color"]};
    }
  }
`;

export const StyledCardSearchPageInput = styled(SearchPageInput)`
  && {
    background-color: ${({ theme }) =>
      theme.colors["card_search_input-background"]} !important;

    input {
      background-color: ${({ theme }) =>
        theme.colors["card_search_input-background"]} !important;
    }
  }
`;

export const StyledDevicePerPageWidget = styled(Menu)`
  margin-top: 0px !important;
  box-shadow: ${({ theme }) =>
    theme.colors["pagination-box-shadow"]} !important;
  .item:hover {
    background-color: ${({ theme }) =>
      theme.colors["pagination-item-background-hover-color"]} !important;
    box-shadow: none !important;
  }
`;

export const StyledSecondaryDevicePerPageWidget = styled(Menu)`
  margin-top: 0px !important;
  box-shadow: ${({ theme }) =>
    theme.colors["pagination-box-shadow"]} !important;
  background-color: ${({ theme }) =>
    theme.colors["card_pagination-item-background-color"]} !important;
  .item:hover {
    background-color: ${({ theme }) =>
      theme.colors["card_pagination-item-background-hover-color"]} !important;
    box-shadow: none !important;
  }
`;

export const AddAllColText = styled(Button)`
  && {
    margin-left: auto;
    margin-right: 12px;
    float: right;
    background: transparent;
    color: ${({ theme }) => theme.colors["primary-color"]};
    outline: none;
    border: none;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: ${({ theme }) => theme.colors["primary-hover-color"]};
    }
  }
`;

export const PrecisionInlineInput = styled(Input)`
  &&& {
    width: 45px;
    margin: 0 5px;
    input {
      padding: 2px 5px;
      text-align: center;
    }
  }
`;

export const StyledAceEditor = styled(AceEditor)`
  height: 300px;
  width: 800px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: ${({ theme }) => theme.colors["ace-editor-border"]};
  box-shadow: ${({ theme }) => theme.colors["ace-editor-box-shadow"]};
`;
