import React, { useState, useEffect, ReactNode } from "react";
import { Button, Modal } from "semantic-ui-react";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";
import { DeviceComponent, IDeviceComponent } from "../../../../BytebeamClient";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";
import { hasSpecialCharacters } from "../../util";

type ErrorMessageType = {
  showValidationMessage: boolean;
  nameErrorMessage: string;
};

interface CreateOrEditDeviceComponentModalProps {
  readonly title: string;
  readonly component: IDeviceComponent;
  readonly components: IDeviceComponent[];
  readonly allComponentsName: Set<string>;
  readonly onSubmit: (component: DeviceComponent) => void;
  readonly trigger: ReactNode;
}

export default function CreateOrEditDeviceComponentModal(
  props: CreateOrEditDeviceComponentModalProps
) {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<DeviceComponent>({
    name: props.component?.name ?? "",
  });
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>({
    showValidationMessage: false,
    nameErrorMessage: "",
  });

  const handleSubmit = () => {
    let name = component.name.trim();

    if (name.trim().length === 0) {
      setErrorMessage({
        showValidationMessage: true,
        nameErrorMessage: "Component Name is Required",
      });
    } else if (hasSpecialCharacters(name.trim())) {
      setErrorMessage({
        showValidationMessage: true,
        nameErrorMessage:
          "Component Name cannot contain special characters except underscore",
      });
    } else if (props.allComponentsName.has(name.trim())) {
      setErrorMessage({
        showValidationMessage: true,
        nameErrorMessage: "Component Name Already Exist",
      });
    } else {
      setErrorMessage({
        showValidationMessage: false,
        nameErrorMessage: "",
      });
      onModalClose();
      props.onSubmit(component);
    }
  };

  const onModalClose = () => {
    setOpen(false);
    if (props.component.name === "") {
      setComponent((prevValues) => ({
        ...prevValues,
        name: "",
      }));
    }

    setErrorMessage({
      showValidationMessage: false,
      nameErrorMessage: "",
    });
  };

  // Set the component state when the modal is opened
  useEffect(() => {
    if (open)
      setComponent({
        name: props.component?.name ?? "",
      });
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={props.trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Name</StyledLabel>
          <input
            autoFocus
            placeholder="Engine, BMS, etc..."
            value={component.name}
            onChange={(e) => {
              setComponent({
                ...component,
                name: e.target.value.replace(" ", "_"),
              });
            }}
          />
        </StyledInput>
        {errorMessage.showValidationMessage &&
          errorMessage.nameErrorMessage !== "" && (
            <ErrorMessage id={"componentNameErrorSpan"}>
              {errorMessage.nameErrorMessage}
            </ErrorMessage>
          )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
