import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import {
  Device,
  DeviceFilterOption,
  Filters,
  Key,
  SearchDeviceResponse,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  searchDevices,
} from "../../../../BytebeamClient";
import { convertDecimalToRoman } from "../util";
import { breakpoints } from "../../../common/breakpoints";
import PhaseMenuTab from "./PhaseMenuTab";
import { PhaseData } from "./NewAction";
import UploadDeviceListModal from "./UploadDeviceListModal";
import PhaseContent from "./PhaseContent";
import { debounce } from "lodash";

export const PhaseSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const PhasesMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  padding: 6px 0px;
  overflow-x: scroll;
  scroll-behavior: smooth;

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 75vw;
  }
  @media (max-width: ${breakpoints.sm + 1}px) {
    max-width: 90vw;
  }
`;

export const AddFiltersButton = styled(Icon)`
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  cursor: pointer;
`;

type PhaseErrorsMessage = { [key: string]: string | null };

interface PhaseControlSectionProps {
  readonly phasesData: PhaseData[];
  readonly setPhasesData: (phases: PhaseData[]) => void;
  readonly isPhasedRollout: boolean;
  readonly setIsPhasedRollout: (isPhasedRollout: boolean) => void;
  readonly shouldTriggerImmediately: boolean;
  readonly setShouldTriggerImmediately: (
    shouldTriggerImmediately: boolean
  ) => void;
}

const PhaseControlSection: React.FC<PhaseControlSectionProps> = ({
  phasesData,
  setPhasesData,
  isPhasedRollout,
  setIsPhasedRollout,
  shouldTriggerImmediately,
  setShouldTriggerImmediately,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const [filters, setFilters] = useState<Filters>({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [page, setPage] = useState<number>(1);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [devices, setDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [phaseErrorsMessage, setPhaseErrorsMessage] =
    useState<PhaseErrorsMessage>({});
  const [activePhase, setActivePhase] = useState<string>(
    `Phase ${convertDecimalToRoman(1)}`
  );
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [pageLimit] = useState<number>(5);
  const [metadataKeysToShow, setMetadataKeysToShow] = useState<string[]>([]);
  const [depth, setDepth] = useState(1);
  const [currentDepth, setCurrentDepth] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});
  const [filtersExist, setFiltersExist] = useState<boolean>(false);

  const refreshDevices = useCallback(
    async (pageNumber: number, myFilters: Filters) => {
      setDevicesLoading(true);

      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      // setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices
      setTimeout(async () => {
        const status = "active";
        try {
          const devices = await searchDevices(
            { metaDatafilters: myFilters },
            pageNumber,
            pageLimit,
            status,
            abortControllerRef.current.signal
          );
          setDevices(devices);
        } finally {
          setDevicesLoading(false);
        }
      });
    },
    [pageLimit]
  );

  /**
   * Updates the filter options based on the provided device filters and optional keys.
   * This function fetches the latest filter options, maps them according to the provided keys (if any),
   * and updates the state to reflect the new filter options.
   *
   * @param {DeviceFilters} filters - The current set of filters applied to devices.
   * @param {Key[]} [keys] - Optional. A list of keys to filter the filter options by. If not provided, all filter options are used.
   * @returns {Promise<void>} A promise that resolves when the filter options have been refreshed and the state has been updated.
   **/
  const refreshFilterOptions = useCallback(
    async (filters: Filters, keys?: Key[]): Promise<void> => {
      setFilterLoading(true);
      try {
        const allFilterOptions = await fetchDeviceFilterOptions(filters);
        const filterOptionsMap = allFilterOptions.reduce(
          (acc, { filterName, filterValues }) => {
            acc[filterName] = filterValues;
            return acc;
          },
          {} as { [key: string]: any[] }
        );

        if (keys?.length) {
          const mappedFilterOptions = keys.map(({ key }) => ({
            filterName: key,
            filterValues: filterOptionsMap[key] || [],
          }));
          setFilterOptions(mappedFilterOptions);
        } else {
          // If no specific keys are provided, use all filter options as is
          setFilterOptions(allFilterOptions);
        }
      } catch (error) {
        console.error("Failed to refresh filter options:", error);
      } finally {
        setFilterLoading(false);
      }
    },
    []
  );

  const fetchMetadataKeys = useCallback(async () => {
    setDevicesLoading(true);
    try {
      const keys = await fetchAllMetadataKeys();
      const idKey = { key: "id" };
      const allKeys = [idKey, ...keys];
      setAllowedFilterBys(allKeys);
      refreshFilterOptions(filters, allKeys);
    } catch (error) {
      console.error("Failed to fetch metadata keys:", error);
    }
  }, [filters, refreshFilterOptions]);

  const onPageChange = useCallback(
    (e, { activePage }) => {
      e.preventDefault();

      setDevicesLoading(true);
      setPage(activePage as number);
      refreshDevices(activePage as number, filters);
    },
    [filters, refreshDevices]
  );

  const resetPhaseFilters = useCallback(
    (name: string) => {
      const selectedPhase = phasesData.find((phase) => phase.name === name);
      if (!selectedPhase) return;

      selectedPhase.info.device_ids = [];
      selectedPhase.info.filter = {};
      selectedPhase.info.type = "fixed-list";
      selectedPhase.info.fraction = 0;

      const updatedPhasesData: PhaseData[] = phasesData.map((phase) =>
        phase.name === name ? selectedPhase : phase
      );
      setPhasesData(updatedPhasesData);
    },
    [phasesData, setPhasesData]
  );

  const resetFilters = useCallback(
    (name?: string) => {
      setFilterLoading(true);
      setDevicesLoading(true);
      setPage(1);
      setAllowedFilterBys([]);
      setFilters({});
      setSelectedDevices({ devices: [], count: 0 });
      setAllSelected(false);
      setSelectedFilters({});
      if (name) resetPhaseFilters(name);
    },
    [resetPhaseFilters]
  );

  const addPhase = useCallback(() => {
    const newPhase: PhaseData = {
      id: phasesData.length + 1,
      name: `Phase ${convertDecimalToRoman(phasesData.length + 1)}`,
      trigger_on: { success_percentage: 100 },
      info: { type: "fixed-list", device_ids: [] },
    };

    const newPhasesData: PhaseData[] = [...phasesData, newPhase];
    setPhasesData(newPhasesData);
    setActivePhase(newPhase.name);

    // Reset filters when adding a new phase
    resetFilters(newPhase.name);
  }, [phasesData, resetFilters, setPhasesData]);

  const removePhase = useCallback(
    (e) => {
      e.stopPropagation();

      const newPhasesData = phasesData.filter(
        (phase) => phase.id !== phasesData.length
      );
      setPhasesData(newPhasesData);
      setActivePhase(`Phase ${convertDecimalToRoman(phasesData.length - 1)}`);

      // Reset filters when removing a phase
      resetFilters();

      const selectedPhase = newPhasesData.find(
        (phase) => phase.id === newPhasesData.length
      );

      if (!selectedPhase) return;

      const { type, filter, device_ids } = selectedPhase.info;

      const newFilters = filter ?? {};
      setFilters(newFilters);
      setSelectedFilters(newFilters);

      if (type === "filter-fraction" || type === "filter-fraction-lazy") {
        setAllSelected(!Object.keys(newFilters).length);
      } else if (type === "fixed-list") {
        const newDevices = device_ids?.map((id) => ({ id }) as Device) ?? [];
        setSelectedDevices({ devices: newDevices, count: newDevices.length });
      }
    },
    [phasesData, resetFilters, setPhasesData]
  );

  const updateFilters = useCallback(
    (myFilters: Filters, allowedFilterBy: Key[]) => {
      setPage(1);
      setDevicesLoading(true);
      setSelectedDevices({ devices: [], count: 0 });
      setAllSelected(false);

      refreshFilterOptions(myFilters, allowedFilterBy);
      refreshDevices(1, myFilters);

      const updatedPhasesData: PhaseData[] = phasesData.map((phase) => {
        if (phase.name === activePhase) {
          phase.info = {
            ...phase.info,
            type: Object.keys(myFilters).length
              ? "filter-fraction"
              : "fixed-list",
            filter: myFilters ?? {},
            fraction: Object.keys(myFilters).length ? 100 : 0,
          };
        }
        return phase;
      });
      setPhasesData(updatedPhasesData);
      setAllSelected(Boolean(Object.keys(myFilters).length));
    },
    [
      activePhase,
      phasesData,
      refreshDevices,
      refreshFilterOptions,
      setPhasesData,
    ]
  );

  // Create a ref for the debounced function
  const debouncedUpdateFiltersRef = useRef(debounce(updateFilters, 1000));

  /** Update the debounced function whenever updateFilters changes
   * this is essential as if it is used directly then because of debouncing
   * it does not take latest values of states
   * even if the states are passed as dependencies in callback
   */
  useEffect(() => {
    const currentDebouncedFetch = debouncedUpdateFiltersRef.current;
    debouncedUpdateFiltersRef.current = debounce(updateFilters, 1000);

    // Cleanup function to cancel the debounced call when the component unmounts
    return () => currentDebouncedFetch.cancel();
  }, [updateFilters]);

  // simplify use for the debounced function
  const debouncedUpdateFilters = (...args) => {
    debouncedUpdateFiltersRef.current(...args);
  };

  const handlePhaseClick = useCallback(
    (name: string, phaseData?: PhaseData) => {
      setActivePhase(name);
      resetFilters();

      const selectedPhase =
        phaseData ?? phasesData.find((phase) => phase.name === name);
      if (!selectedPhase) return;

      const { type, filter, device_ids } = selectedPhase.info;
      const newDevices = device_ids?.map((id) => ({ id }) as Device) ?? [];

      const newFilters = filter ?? {};
      setFilters(newFilters);
      setSelectedFilters(newFilters);

      if (type === "filter-fraction" || type === "filter-fraction-lazy") {
        setFiltersExist(Boolean(Object.keys(newFilters).length));
        setAllSelected(Boolean(Object.keys(newFilters).length));
        setSelectedDevices({
          devices: newDevices,
          count: devices?.count ?? newDevices.length,
        });
      } else if (type === "fixed-list") {
        setSelectedDevices({ devices: newDevices, count: newDevices.length });
      }
    },
    [devices?.count, phasesData, resetFilters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * this useEffect triggers when:
   * * initial render
   * * new phase added
   * * currently selected phase changes
   *
   * this useEffect DOES NOT trigger when:
   * * change in filters
   */
  useEffect(() => {
    const initialFetch = () => {
      fetchMetadataKeys();
      refreshDevices(1, filters);
    };

    if (!allowedFilterBys.length) {
      initialFetch();
    }
  }, [filters, fetchMetadataKeys, refreshDevices]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const phaseName = `Phase ${convertDecimalToRoman(1)}`;
    if (activePhase !== phaseName) handlePhaseClick(phaseName);
  }, [isPhasedRollout]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollRef.current) {
      const scrollable = scrollRef.current;
      scrollable.scrollLeft = scrollable.scrollWidth - scrollable.clientWidth;
    }
  }, [phasesData.length, activePhase]);

  return (
    <>
      <UploadDeviceListModal
        setIsPhasedRollout={setIsPhasedRollout}
        setPhasesData={setPhasesData}
        handlePhaseClick={handlePhaseClick}
        fetchMetadataKeys={fetchMetadataKeys}
        refreshDevices={refreshDevices}
        disable={devicesLoading || filterLoading || devices?.count === 0}
      />
      {isPhasedRollout && (
        <PhasesMenuWrapper ref={scrollRef}>
          {phasesData.map((phase) => (
            <PhaseMenuTab
              key={phase.id}
              id={phasesData.length + 2 - phase.id}
              phasetabsum={phasesData.length + 1}
              tempPhaseRemoveCheck={phase.id === phasesData.length}
              firstElement={phase.id === 1}
              name={phase.name}
              active={activePhase === phase.name}
              removePhase={removePhase}
              onClick={() => {
                if (activePhase !== phase.name) handlePhaseClick(phase.name);
              }}
            />
          ))}
          <PhaseMenuTab
            id={1}
            lastElement
            loading={filterLoading || devicesLoading}
            onClick={() => !filterLoading && !devicesLoading && addPhase()}
          />
        </PhasesMenuWrapper>
      )}
      {phasesData.map((phase) => (
        <PhaseContent
          key={phase.id}
          phase={phase}
          phasesData={phasesData}
          setPhasesData={setPhasesData}
          activePhase={activePhase}
          phaseErrorsMessage={phaseErrorsMessage}
          setPhaseErrorsMessage={setPhaseErrorsMessage}
          allowedFilterBys={allowedFilterBys}
          filterLoading={filterLoading}
          filters={filters}
          setFilters={setFilters}
          debouncedUpdateFilters={debouncedUpdateFilters}
          filterOptions={filterOptions}
          resetFilters={resetFilters}
          devices={devices}
          devicesLoading={devicesLoading}
          setDevicesLoading={setDevicesLoading}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          page={page}
          pageLimit={pageLimit}
          onPageChange={onPageChange}
          metadataKeysToShow={metadataKeysToShow}
          setMetadataKeysToShow={setMetadataKeysToShow}
          currentDepth={currentDepth}
          setCurrentDepth={setCurrentDepth}
          depth={depth}
          setDepth={setDepth}
          isPhasedRollout={isPhasedRollout}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          filtersExist={filtersExist}
          setFiltersExist={setFiltersExist}
          shouldTriggerImmediately={shouldTriggerImmediately}
          setShouldTriggerImmediately={setShouldTriggerImmediately}
        />
      ))}
    </>
  );
};

export default PhaseControlSection;
