import React, { useState } from "react";
import AceEditor from "react-ace";
import styled from "styled-components";
import { useUser } from "../../../../context/User.context";
import Toggle from "../../../common/Toggle";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { StyledNonBoldHeader } from "./NewAction";
import TimeoutDropdown from "../../../common/TimeoutDropdown";
import ThemeSchema from "../../../../theme/schema";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-chrome";

const AdvancedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  margin-left: 24px;
`;

const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const Header = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

type AdvanceSettingsSectionProps = {
  readonly endTimestamp: Date | number;
  readonly releaseNotes: string;
  readonly action: string;
  readonly setEndTimestamp: (endTimestamp: Date | number) => void;
  readonly setReleaseNotes: (releaseNotes: string) => void;
  readonly setRetryUntil: (retryUntil: Date | number) => void;
  readonly textEditorDimensions?: { height: string; width: string };
};

function AdvanceSettingsSection({
  endTimestamp,
  releaseNotes,
  action,
  setEndTimestamp,
  setReleaseNotes,
  setRetryUntil,
  textEditorDimensions = { height: "300px", width: "800px" },
}: AdvanceSettingsSectionProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [autoRetry, setAutoRetry] = useState<boolean>(false);
  const [isEnabledAdvancedSettings, setIsEnabledAdvancedSettings] =
    useState<boolean>(false);

  const handleToggleChange = () => {
    const newAutoRetry = !autoRetry;
    setAutoRetry(newAutoRetry);
    setRetryUntil(newAutoRetry ? endTimestamp : 0);
  };

  const handleTimeoutChange = (timeout: number | Date) => {
    setEndTimestamp(timeout);
    if (autoRetry) {
      setRetryUntil(timeout);
    }
  };

  return (
    <>
      <AdvancedSectionContainer style={{ marginLeft: "0px" }}>
        <AdvancedSectionItem>
          <Header as="h3">Advanced Settings</Header>
          <Toggle
            id="action-toggle"
            size="large"
            bgcolor="#05DB0A"
            checked={isEnabledAdvancedSettings}
            onChange={() =>
              setIsEnabledAdvancedSettings(!isEnabledAdvancedSettings)
            }
          />
        </AdvancedSectionItem>
      </AdvancedSectionContainer>
      {isEnabledAdvancedSettings && (
        <>
          <AdvancedSectionContainer
            style={{ marginTop: "36px", flexWrap: "wrap-reverse", gap: "24px" }}
          >
            <AdvancedSectionItem>
              <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                Auto Retry
              </StyledNonBoldHeader>
              <Toggle
                id="action-toggle"
                size="small"
                bgcolor="#05DB0A"
                onChange={handleToggleChange}
                checked={autoRetry}
              />
            </AdvancedSectionItem>
            <AdvancedSectionItem>
              <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                Mark Action as Timed Out after
              </StyledNonBoldHeader>
              <div>
                <TimeoutDropdown
                  timeoutDuration={endTimestamp}
                  setTimeoutDuration={handleTimeoutChange}
                />
              </div>
            </AdvancedSectionItem>
          </AdvancedSectionContainer>

          {action === "update_firmware" && (
            <AdvancedSectionContainer>
              <AdvancedSectionItem
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginTop: "18px",
                }}
              >
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Release notes
                </StyledNonBoldHeader>
                <AceEditor
                  height={textEditorDimensions.height}
                  width={textEditorDimensions.width}
                  placeholder="Enter Release Notes"
                  mode="markdown"
                  theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
                  name="release-notes"
                  onChange={setReleaseNotes}
                  value={releaseNotes}
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "4px",
                    border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
                    boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
                  }}
                />
              </AdvancedSectionItem>
            </AdvancedSectionContainer>
          )}
        </>
      )}
    </>
  );
}

export default AdvanceSettingsSection;
