import React, { Component } from "react";
import { updateDeviceMetadata } from "../../../../BytebeamClient";
import { DisplayIf, ButtonIcon } from "../../util";
import { Input } from "semantic-ui-react";
import styled from "styled-components";
import TextWithToolTip from "./TextWithToolTip";
import { User } from "../../../../util";
import { beamtoast } from "../../../common/CustomToast";

type MetadataCellProps = {
  id: string | number;
  user: User;
  activeAccordionIndex: number;
  keyName: string;
  text: string | number;
  editable: boolean;
};

type MetadataCellState = {
  state: string;
  text: string | number;
};

export const StyledIcon = styled(ButtonIcon)`
  margin-left: 12px !important;
`;

const StyledInput = styled.input`
  max-width: 96px !important;
  padding: 0px 8px !important;
  background-color: transparent !important;
  color: ${({ theme }) => theme.colors["text"]} !important;
`;

const MetadataCellContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  white-space: nowrap;
`;

export class MetadataCell extends Component<
  MetadataCellProps,
  MetadataCellState
> {
  constructor(props) {
    super(props);

    this.state = {
      state: "view",
      text: props.text,
    };
  }

  async updateMetadata() {
    try {
      if (
        this.props.keyName ===
          this.props.user?.["tenant-settings"]?.["serial-key"] &&
        !this.state.text
      ) {
        beamtoast.error("Serial-key metadata cannot be empty");
        return;
      } else if (
        (this.props.text || this.state.text) &&
        this.props.text !== this.state.text
      ) {
        this.setState({ state: "updating" });

        const requestBody = {
          id: this.props.id,
          key: this.props.keyName,
          value:
            (this.state.text as string).trim() === ""
              ? null
              : (this.state.text as string).trim(),
        };

        await updateDeviceMetadata(requestBody);
        beamtoast.success("Metadata updated successfully");
      }
      this.setState({ state: "view" });
    } catch (error: any) {
      console.error("Error occurred while updating metadata:", error);
      if (error?.response?.status === 401 || error?.response?.status === 403)
        beamtoast.error("Metadata update failed, device is not active!");
      else if (
        error?.response?.status === 400 &&
        error?.response?.data?.error?.includes("unique")
      ) {
        beamtoast.error(
          `Please provide a unique value for ' ${this.props.user?.["tenant-settings"]?.["serial-key"]}' : ${this.state.text} already exists!`
        );
      } else {
        beamtoast.error("Error occurred while updating metadata");
      }
      // Revert back to the previous value if the update fails.
      this.setState({ state: "view", text: this.props.text });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") this.updateMetadata();
    if (e.key === "Escape")
      this.setState({ state: "view", text: this.props.text });
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value });
  };

  componentDidUpdate(prevProps: Readonly<MetadataCellProps>): void {
    if (this.props.id === this.props.activeAccordionIndex)
      if (prevProps.activeAccordionIndex !== this.props.activeAccordionIndex)
        this.setState({ state: "view", text: this.props.text });
  }

  render() {
    if (this.state.state === "view") {
      return (
        <MetadataCellContainer className="metadata-cell-container">
          <TextWithToolTip text={this.state.text} />
          <DisplayIf cond={this.props.editable}>
            <StyledIcon
              id={`${this.props.id}-edit-${this.props.keyName}`}
              name="pencil"
              className="metadata-cell-icon"
              onClick={() => {
                this.setState({ state: "edit" });
              }}
            />
          </DisplayIf>
        </MetadataCellContainer>
      );
    }
    if (this.state.state === "edit") {
      return (
        <MetadataCellContainer>
          <Input focus style={{ borderWidth: "1px" }}>
            <StyledInput
              id={`${this.props.id}-input-${this.props.keyName}`}
              autoFocus
              onKeyDown={(e) => this.handleKeyDown(e)}
              value={this.state.text}
              onChange={this.handleChange}
            />
          </Input>
          <StyledIcon
            id={`${this.props.id}-submit-${this.props.keyName}`}
            name="check"
            onClick={() => this.updateMetadata()}
          />
          <StyledIcon
            id={`${this.props.id}-cancel-${this.props.keyName}`}
            name="close"
            onClick={() =>
              this.setState({ state: "view", text: this.props.text })
            }
          />
        </MetadataCellContainer>
      );
    }

    if (this.state.state === "updating") {
      return (
        <MetadataCellContainer>
          <TextWithToolTip text={this.state.text} />
          <StyledIcon name="refresh" loading />
        </MetadataCellContainer>
      );
    }
  }
}
