import React, { useState } from "react";
import ConfirmationModal from "../../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../../common/ConfirmationModalMessage";
import { beamtoast } from "../../../../common/CustomToast";
import EditFirmwareBundleModal from "./EditFirmwareBundleModal";
import {
  deleteFirmwareBundle,
  FirmwareBundle,
  FirmwareType,
} from "../../../../../BytebeamClient";
import { Accordion, Button, Grid, Icon, Table } from "semantic-ui-react";
import {
  ButtonIcon,
  capitalizeFirstLetter,
  DisplayIf,
  getHumanReadableFileSizeString,
} from "../../../util";
import { useUser } from "../../../../../context/User.context";
import SlicedTextPopUp from "../../../DeviceManagement/Devices/SlicedTextPopUp";
import BackupIcon from "../../../../../assets/svg/BackupIcon";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import CreateFirmwareBundleModal from "./CreateFirmwareBundleModal";

type FirmwareBundlesProps = {
  readonly firmwareBundles: FirmwareBundle[];
  readonly fillFirmwareBundlesTable: () => void;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
};

export function FirmwareBundles(props: FirmwareBundlesProps) {
  const {
    firmwareBundles,
    fillFirmwareBundlesTable,
    componentsList,
    firmwareVersions,
  } = props;

  const { user } = useUser();
  const permissions = user?.role?.permissions || {};

  const [firmwareBundle, setFirmwareBundle] = useState<FirmwareBundle>(
    {} as FirmwareBundle
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const [createFirmwareModalIsOpen, setCreateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [editFirmwareBundleModalIsOpen, setEditFirmwareBundleModalIsOpen] =
    useState<boolean>(false);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const handleDelete = async (bundle_id: string) => {
    try {
      const res = await deleteFirmwareBundle(bundle_id);
      if (res) {
        beamtoast.success("Firmware Bundle Deleted Successfully");
        fillFirmwareBundlesTable();
      }
    } catch (error) {
      beamtoast.error("Error Deleting Firmware Bundle");
    }
  };

  return (
    <Grid>
      <CreateFirmwareBundleModal
        isOpen={createFirmwareModalIsOpen}
        close={() => setCreateFirmwareModalIsOpen(false)}
        componentsList={componentsList}
        firmwareVersions={firmwareVersions}
        fillFirmwareBundlesTable={fillFirmwareBundlesTable}
      />
      <EditFirmwareBundleModal
        isOpen={editFirmwareBundleModalIsOpen}
        close={() => setEditFirmwareBundleModalIsOpen(false)}
        firmwareBundle={firmwareBundle}
        fillFirmwareBundlesTable={fillFirmwareBundlesTable}
      />
      <Grid.Row>
        <Grid.Column width="6" floated="right" style={{ padding: "0px" }}>
          <DisplayIf cond={permissions.editFirmwares}>
            <Button
              id="upload_firmware_button"
              primary
              floated="right"
              onClick={() => {
                setCreateFirmwareModalIsOpen(true);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                whiteSpace: "nowrap",
              }}
            >
              <BackupIcon height="16px" altColor="#fff" />
              Create Firmware Bundle
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ padding: "0px" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  Bundle Id
                </Table.HeaderCell>
                {/* <Table.HeaderCell textAlign="center">Bundle Version</Table.HeaderCell> */}
                <Table.HeaderCell textAlign="center">
                  File Size
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Uncompressed File Size
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Upload Status
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Created At
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Checksum</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Options</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {firmwareBundles.length !== 0 ? (
                firmwareBundles.map((bundle, index) => (
                  <>
                    <Table.Row
                      key={bundle.id}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <Table.Cell textAlign="center">
                        <Icon
                          style={{ float: "left" }}
                          name={
                            index === activeIndex ? "angle down" : "angle right"
                          }
                        />
                        <SlicedTextPopUp text={bundle.id} length={18} />
                      </Table.Cell>
                      {/* <Table.Cell textAlign="center">
                          <SlicedTextPopUp text={bundle.version} length={18} />
                        </Table.Cell> */}
                      <Table.Cell textAlign="center">
                        {bundle.content_length
                          ? getHumanReadableFileSizeString(
                              bundle.content_length
                            )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.uncompressed_size
                          ? getHumanReadableFileSizeString(
                              bundle.uncompressed_size
                            )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.upload_status}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {new Date(bundle.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.checksum ? (
                          <SlicedTextPopUp text={bundle.checksum} length={14} />
                        ) : (
                          "N/A"
                        )}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "18px",
                        }}
                      >
                        <ButtonIcon
                          name="edit"
                          title="Edit Firmware Bundle"
                          onClick={() => {
                            setFirmwareBundle(bundle);
                            setEditFirmwareBundleModalIsOpen(true);
                          }}
                        />
                        <ConfirmationModal
                          prefixContent="Delete Firmware Bundle"
                          expectedText={bundle.id}
                          onConfirm={() => handleDelete(bundle.id)}
                          trigger={
                            <ButtonIcon
                              link
                              title="Delete Firmware Bundle"
                              name="trash"
                            />
                          }
                          message={
                            <ConfirmationModalMessage
                              name={bundle.id}
                              type={"Firmware Bundle"}
                              specialMessage=""
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    {activeIndex === index && (
                      <Table.Row key={`${bundle.id}-details`}>
                        <Table.Cell colSpan="7" style={{ paddingTop: "0px" }}>
                          <Accordion
                            style={{
                              padding: "0px 32px 18px 32px",
                            }}
                          >
                            <Accordion.Title
                              active={activeIndex === index}
                              index={index}
                              onClick={() => handleAccordionClick(index)}
                            >
                              Firmware Details
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                              <Table>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell textAlign="center">
                                      Component Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Version Number
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      File Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Content Length
                                    </Table.HeaderCell>
                                    {/* <Table.HeaderCell textAlign="center">
                                        Uncompressed Size
                                      </Table.HeaderCell> */}
                                    <Table.HeaderCell textAlign="center">
                                      Checksum
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {bundle.firmwares.map((firmware, idx) => (
                                    <Table.Row key={`${bundle.id}-${idx}`}>
                                      <Table.Cell textAlign="center">
                                        {capitalizeFirstLetter(
                                          firmware.component_name
                                        )}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        <SlicedTextPopUp
                                          text={firmware.version_number}
                                          length={24}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        <SlicedTextPopUp
                                          text={firmware.file_name}
                                          length={24}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        {getHumanReadableFileSizeString(
                                          firmware.content_length
                                        )}
                                      </Table.Cell>
                                      {/* <Table.Cell textAlign="center">
                                          {firmware.uncompressed_size
                                            ? getHumanReadableFileSizeString(
                                                firmware.uncompressed_size
                                              )
                                            : "--"}
                                        </Table.Cell> */}
                                      <Table.Cell textAlign="center">
                                        {firmware.checksum ? (
                                          <SlicedTextPopUp
                                            text={firmware.checksum}
                                            length={24}
                                          />
                                        ) : (
                                          "N/A"
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </Accordion.Content>
                          </Accordion>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell textAlign="center" colSpan="7">
                    <ErrorMessage
                      marginTop="30px"
                      message={"No Firmware Bundles Uploaded!"}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
