import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { User } from "../../../../util";

import { ModuleStyledAccordian } from "./ModulePermissions";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { ThinDivider } from "../../Dashboards/Panel/util";
import SerialMetadata from "./SerialMetadata";
import DefaultGlobalTimeout from "./DefaultGlobalTimeout";

export type TenantLevelSettingsProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 35px;
`;

export const SettingRowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingRowLabel = styled.p`
  flex: 1;
  font-weight: 700;
  margin: 0px 10px 0px 0px;
`;

export const SettingRowEditableSection = styled.div`
  flex: 1;
  display: flex;
`;

export const IconContainer = styled.div`
  margin-left: 10px;
  gap: 6px;
  display: flex;
  align-items: center;
`;

const TenantLevelSettings = ({
  user,
  getCurrentUser,
}: TenantLevelSettingsProps) => {
  const [isTenantsSettingAccordianActive, setIsTenantsSettingAccordianActive] =
    useState<boolean>(true);

  const handleSerialKeyAccordianClick = () => {
    setIsTenantsSettingAccordianActive((prev) => !prev);
  };

  return (
    <ModuleStyledAccordian>
      <Accordion fluid>
        <Accordion.Title
          active={isTenantsSettingAccordianActive}
          onClick={handleSerialKeyAccordianClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledHeader
              as="h3"
              style={{
                margin: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Tenant Settings
            </StyledHeader>
            <Icon
              style={{ fontSize: "24px" }}
              name={
                isTenantsSettingAccordianActive ? "angle down" : "angle right"
              }
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={isTenantsSettingAccordianActive}>
          <ThinDivider style={{ margin: "15px 0px 15px 0px" }} />
          <Container>
            <SerialMetadata user={user} getCurrentUser={getCurrentUser} />
            <DefaultGlobalTimeout user={user} getCurrentUser={getCurrentUser} />
          </Container>
        </Accordion.Content>
      </Accordion>
    </ModuleStyledAccordian>
  );
};

export default TenantLevelSettings;
