import React, { useState, useMemo, useEffect } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  DeviceComponent,
  IDeviceComponent,
  IDeviceComponentResult,
  createDeviceComponent,
  deleteDeviceComponent,
  fetchAllDeviceComponents,
  updateDeviceComponent,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { TableHeaderCellWithSorting } from "../../../common/TableHeaderCellWithSorting";
import CreateOrEditDeviceComponentModal from "./CreateOrEditDeviceComponentModal";
import { ButtonIcon } from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import { beamtoast } from "../../../common/CustomToast";

interface CreateComponentButtonProps {
  readonly onUpdate: () => void;
  readonly components: IDeviceComponent[];
}

function CreateComponentButton(props: CreateComponentButtonProps) {
  async function handleSubmit(component: DeviceComponent) {
    try {
      await createDeviceComponent(component);
      Mixpanel.track("Created Device Component", {
        componentName: component.name,
      });
      beamtoast.success("Device Component created successfully");
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Device Component creation",
        error: JSON.stringify(e),
      });
      beamtoast.error("Failed to create Device Component");
      console.log(e);
    }
  }

  return (
    <CreateOrEditDeviceComponentModal
      title="Create Device Component"
      onSubmit={async (component) => await handleSubmit(component)}
      component={{ id: 0, name: "", is_deleted: false }}
      components={props.components}
      allComponentsName={new Set(props.components?.map((c) => c.name))}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Add Device Component
        </Button>
      }
    />
  );
}

interface EditComponentButtonProps {
  readonly component: IDeviceComponent;
  readonly components: IDeviceComponent[];
  readonly onUpdate: () => void;
}

function EditComponentButton(props: EditComponentButtonProps) {
  async function handleSubmit(component: DeviceComponent) {
    try {
      await updateDeviceComponent(props.component.id, component);
      Mixpanel.track("Edited Device Component", {
        componentName: component.name,
      });
      beamtoast.success("Device Component edited successfully");
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure during editing device component", {
        type: "device component editing",
        error: JSON.stringify(e),
      });
      beamtoast.error("Failed to edit Device Component");
      console.log(e);
    }
  }

  return (
    <CreateOrEditDeviceComponentModal
      title="Edit Device Component"
      onSubmit={async (component) => await handleSubmit(component)}
      component={props.component}
      components={props.components}
      allComponentsName={new Set(props.components.map((c) => c.name))}
      trigger={
        <ButtonIcon
          link
          name="pencil"
          title="Edit Device Component"
          disabled={props.component.name === "main"}
        />
      }
    />
  );
}

export default function DeviceComponents() {
  const [components, setComponents] = useState<IDeviceComponent[]>([]);
  const [filteredComponents, setFilteredComponents] = useState<
    IDeviceComponent[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | undefined;
  }>({ key: "id", direction: "ascending" });

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res: IDeviceComponentResult = await fetchAllDeviceComponents();
      setComponents(res.results);
      setFilteredComponents(res.results);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  const handleDelete = async (component) => {
    try {
      await deleteDeviceComponent(component.id);
      Mixpanel.track("Deleted Device Component", {
        componentId: component.id,
        componentName: component.name,
      });
      beamtoast.success(
        `Device Component ${component.name} deleted successfully`
      );
      handleUpdate();
    } catch (e) {
      Mixpanel.track("Failure during deleting device component", {
        type: "device component deletion",
        error: JSON.stringify(e),
      });
      beamtoast.error(`Failed to delete Device Component ${component.name}`);
      console.log(e);
    }
  };

  const onSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedComponents = useMemo(() => {
    if (sortConfig.key) {
      filteredComponents?.sort((a, b) => {
        let valueA = a?.[sortConfig.key];
        let valueB = b?.[sortConfig.key];

        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredComponents;
  }, [filteredComponents, sortConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = "Device Components | Bytebeam";
    handleUpdate();
  }, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading device components..."
      />
    );
  }

  return (
    <Layout
      buttons={
        <CreateComponentButton
          onUpdate={handleUpdate}
          components={components}
        />
      }
    >
      <Table celled fixed>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellWithSorting
              label="Id"
              columnKey="id"
              onSort={onSort}
              sortConfig={sortConfig}
            />
            <TableHeaderCellWithSorting
              label="Name"
              columnKey="name"
              onSort={onSort}
              sortConfig={sortConfig}
            />
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedComponents?.length !== 0 ? (
            sortedComponents?.map((component) => (
              <Table.Row key={component.name}>
                <Table.Cell>{component.id}</Table.Cell>
                <Table.Cell>{component.name}</Table.Cell>
                <Table.Cell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "18px",
                      flexWrap: "nowrap",
                    }}
                  >
                    <EditComponentButton
                      component={component}
                      onUpdate={handleUpdate}
                      components={components}
                    />
                    <ConfirmationModal
                      prefixContent="Delete Device Component"
                      expectedText={component.name}
                      onConfirm={() => handleDelete(component)}
                      trigger={
                        <ButtonIcon
                          link
                          name="trash"
                          title="Delete Device Component"
                          disabled={component.name === "main"}
                        />
                      }
                      message={
                        <ConfirmationModalMessage
                          name={component.name}
                          type={"Device Component"}
                          specialMessage=""
                        />
                      }
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colspan={3}>
                <ErrorMessage
                  marginTop="30px"
                  message={"No Device Components found!"}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
