import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import {
  createFirmwareBundle,
  FirmwareType,
} from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";
import { EditAnimatedMetaDropdown } from "../../../Dashboards/Panel/util";

type ComponentWiseFirmwareRowProps = {
  readonly firmware: {
    id: number;
    component_name: string;
    version_number: string;
  };
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly firmwaresList: {
    id: number;
    component_name: string;
    version_number: string;
  }[];
  readonly setFirmwaresList: (
    firmwareList: {
      id: number;
      component_name: string;
      version_number: string;
    }[]
  ) => void;
};

function ComponentWiseFirmwareRow(props: ComponentWiseFirmwareRowProps) {
  const {
    firmware,
    componentsList,
    firmwareVersions,
    firmwaresList,
    setFirmwaresList,
  } = props;

  const [firmwareVersionOptions, setFirmwareVersionOptions] = useState<
    { key: string; value: string; text: string }[]
  >([]);

  function handleAdd() {
    setFirmwaresList([
      ...firmwaresList,
      {
        id: firmwaresList.length,
        component_name: "",
        version_number: "",
      },
    ]);
  }

  function handleDelete() {
    setFirmwaresList(firmwaresList.filter((fw) => fw.id !== firmware.id));
  }

  function handleSelectComponent(e, data) {
    e.preventDefault();

    const newFirmwaresList = [...firmwaresList];
    newFirmwaresList.map((newFirmware) => {
      if (newFirmware.id === firmware.id) {
        newFirmware.component_name = data.value;
        newFirmware.version_number = "";
      }

      return newFirmware;
    });
    setFirmwaresList(newFirmwaresList);
  }

  function handleSelectedVersions(version: string) {
    const newFirmwaresList = [...firmwaresList];
    newFirmwaresList.map((newFirmware) => {
      if (newFirmware.id === firmware.id) {
        newFirmware.version_number = version;
      }

      return newFirmware;
    });
    setFirmwaresList(newFirmwaresList);
  }

  useEffect(() => {
    setFirmwareVersionOptions(
      firmwareVersions
        .filter((firmwareVersion) => {
          return (
            firmware.component_name === firmwareVersion.device_component_name
          );
        })
        .map((version) => ({
          key: version.version_number,
          value: version.version_number,
          text: version.version_number,
        }))
    );
  }, [firmware.component_name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table.Row>
      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder="Select Component"
          search
          selection
          options={componentsList.map((component) => ({
            key: component,
            value: component,
            text: component,
          }))}
          onChange={handleSelectComponent}
          loading={componentsList.length === 0}
          disabled={componentsList.length === 0}
          elementid={`select_component`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>

      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder={"Select Firmware Version"}
          fluid
          search
          selection
          disabled={firmware.component_name === ""}
          options={firmwareVersionOptions}
          onChange={(e, data) => {
            e.preventDefault();
            handleSelectedVersions(data.value as string);
          }}
          elementid={`select_version`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>

      <Table.Cell width={2}>
        {(firmwaresList.length === 1 ||
          firmware.id === firmwaresList.at(-1)?.id) &&
        firmwaresList.length < componentsList.length ? (
          <Button onClick={handleAdd} icon="plus" primary />
        ) : (
          <Button onClick={handleDelete} icon="minus" secondary />
        )}
      </Table.Cell>
    </Table.Row>
  );
}

type CreateFirmwareBundleModalProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly fillFirmwareBundlesTable: () => void;
};

function CreateFirmwareBundleModal(props: CreateFirmwareBundleModalProps) {
  const {
    isOpen,
    close,
    componentsList,
    firmwareVersions,
    fillFirmwareBundlesTable,
  } = props;

  const [firmwaresList, setFirmwaresList] = useState<
    { id: number; component_name: string; version_number: string }[]
  >([{ id: 0, component_name: "", version_number: "" }]);

  function onModalClose() {
    // Close Modal
    close();
  }

  async function handleCreateFirmwareBundle() {
    if (firmwaresList.length < 2) {
      beamtoast.error("Please add atleast 2 firmwares to create a bundle");
      return;
    } else if (
      firmwaresList.length !==
      new Set(firmwaresList.map((version) => version.component_name)).size
    ) {
      beamtoast.error("Please select device component only once");
      return false;
    } else if (
      firmwaresList.some(
        (fw) => fw.component_name === "" || fw.version_number === ""
      )
    ) {
      beamtoast.error("Please fill all the fields to create a bundle");
      return;
    }

    try {
      const res = await createFirmwareBundle(
        firmwaresList.map((fw) => ({
          component_name: fw.component_name,
          version_number: fw.version_number,
        }))
      );
      if (res) {
        beamtoast.success("Firmware Bundle Created Successfully");
        fillFirmwareBundlesTable();
        onModalClose();
      }
    } catch (error) {
      beamtoast.error("Failed to Create Firmware Bundle");
      console.error("Failed to Create Firmware Bundle", error);
    }
  }

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      size="small"
      open={isOpen}
    >
      <Modal.Header>Create Firmware Bundle</Modal.Header>
      <Modal.Content>
        <Table fixed>
          <Table.Body>
            {firmwaresList.map((firmware) => (
              <ComponentWiseFirmwareRow
                key={firmware.id}
                firmware={firmware}
                componentsList={componentsList}
                firmwareVersions={firmwareVersions}
                firmwaresList={firmwaresList}
                setFirmwaresList={setFirmwaresList}
              />
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary onClick={() => onModalClose()} content="Cancel" />
        <Button content="Submit" onClick={handleCreateFirmwareBundle} primary />
      </Modal.Actions>
    </Modal>
  );
}

export default CreateFirmwareBundleModal;
